import { baseCasApi } from './casApi';
import { apiHeadersType } from '@/constants/config';

export const authApi = baseCasApi.injectEndpoints({
  endpoints: build => ({
    stVerify: build.query({
      query: ({ ST }) => {
        return {
          url: `/auth/stVerify?ST=${ST}`,
          method: 'GET',
          headers: {
            'content-type': apiHeadersType.jsonType,
          },
        };
      },
    }),
    sctIdVerify: build.query({
      query: ({ sctId }) => {
        return {
          url: `/auth/sctIdVerify`,
          method: 'POST',
          headers: {
            'content-type': apiHeadersType.jsonType,
            sctId,
          },
        };
      },
    }),
    fetchStd: build.query({
      query: ({ sctId, stdData }) => {
        return {
          url: `/auth/fetchStd`,
          method: 'POST',
          headers: {
            'content-type': apiHeadersType.jsonType,
            sctId,
          },
          body: {
            sctData: JSON.parse(stdData),
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useStVerifyQuery, useSctIdVerifyQuery, useFetchStdQuery } = authApi;
