import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { HIS_AUTO_SYNC_LAB } from '@/constants/config';

const characterSlice = createSlice({
  name: 'characterPage/character',
  initialState: {
    characterList: [],
  },
  reducers: {
    extraCharacterList: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          characterList: action.payload.map(c => (c.name === HIS_AUTO_SYNC_LAB ? { ...c, disabled: true } : c)),
        };
      }
    },
    updateCharacterList: (state, action) => {
      const tempCharacterList = state.characterList.some(character => character._id === action.payload._id)
        ? state.characterList.map(character => {
            if (character._id !== action.payload._id) return character;
            return { ...action.payload, count: character.count };
          })
        : [...state.characterList, { ...action.payload, count: 0 }];

      return {
        ...state,
        characterList: [...tempCharacterList],
      };
    },
    removeCharacterFromCharacterList: (state, action) => {
      return {
        ...state,
        characterList: state.characterList.filter(c => c._id !== action.payload),
      };
    },
  },
});

export const { extraCharacterList, updateCharacterList, removeCharacterFromCharacterList } = characterSlice.actions;

export default characterSlice.reducer;
