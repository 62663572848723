import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import homePageReducer from '@/container/HomePage/slice';
import { baseApi } from './services/api';
import { baseCasApi } from './services/cas/casApi';
import workspacePageReducer from '@/container/WorkspacePage/slice';
import memberPageReducer from '@/container/MemberPage/slice';
import historyPageReducer from '@/container/HistoryPage/slice';

export const history = createBrowserHistory();

export default configureStore({
  reducer: {
    [baseCasApi.reducerPath]: baseCasApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    homePageReducer,
    workspacePageReducer,
    memberPageReducer,
    historyPageReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(baseApi.middleware, baseCasApi.middleware),
  devTools: process.env.REACT_APP_ENV === 'dev',
});
