import { combineReducers } from '@reduxjs/toolkit';
import wsSlice from './wsSlice';
import wsListSlice from './wsListSlice';
import notifySlice from './notifySlice';

const workspacePageReducer = combineReducers({
  ws: wsSlice,
  wsList: wsListSlice,
  notify: notifySlice,
});

export default workspacePageReducer;
