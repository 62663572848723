import { isArray, isEmpty, isNil, isPlainObject, isString } from 'lodash';
import {
  regEmail,
  regNoAreaCodeTwPhone,
  regSocialNumber,
  regTwAreaCode,
  regTwCityCall,
  regTwMobile,
  regTwPhone,
  regTwPostCode,
  regTwTaxId,
  validateType,
} from './constants';

export const splitStr = (str, split) => {
  if (!str || !split || !isString(str) || str.length <= 1) return [str];
  return str.split(split);
};

export const getHref = () => {
  return window.location.href;
};
export const redirectHrefTo = href => {
  if (isEmpty(href)) {
    window.location.href = '/';
  } else {
    window.location.href = href;
  }
};

const recursiveParams = (param, path, searchParams) => {
  if (!isNil(param)) {
    if (isPlainObject(param)) {
      for (const [k, v] of Object.entries(param)) {
        recursiveParams(v, path + '[' + k + ']', searchParams);
      }
    } else if (Array.isArray(param)) {
      param.forEach(v => recursiveParams(v, path + '[]', searchParams));
    } else {
      searchParams.append(path, param);
    }
  }
};
export const appendStr = (oriStr, appendQ) => {
  const searchParams = new URLSearchParams();
  Object.entries(appendQ).forEach(([key, value]) => {
    recursiveParams(value, key, searchParams);
  });
  return `${oriStr}?${searchParams.toString()}`;
};

export const strLimit = (value, limit) => {
  if (isEmpty(value) || !isString(value)) return value;

  try {
    let len = 0;
    let currentStr = '';
    const split = value.split('');
    for (let i = 0; i < split.length; i++) {
      // eslint-disable-next-line no-control-regex
      const regex = new RegExp('[^\\x00-\\xff]', 'g');
      if (regex.test(split[i])) len += 2;
      else len++;
      if (len > limit) {
        break;
      }
      currentStr += split[i];
    }
    return currentStr;
  } catch (e) {
    console.error(e);
    return value;
  }
};
export const resetInputSelectionPos = e => {
  const caret = e.target.selectionStart;
  const element = e.target;
  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};

export const arrayFromSame = (length, data) => {
  if (length <= 0 || isEmpty(data)) return [];
  return Array.from({ length }, () => data);
};

export const validateStr = (target, text, num = 20) => {
  if (isEmpty(validateType[target])) return false;

  let regex = '';
  switch (target) {
    case validateType.normal:
      regex = new RegExp('^([\\u4e00-\\u9fa5_a-zA-Z0-9\\s])' + `{1,validNum}$`.replace('validNum', num));
      break;
    case validateType.chinese:
      regex = new RegExp('^([\\u4e00-\\u9fa5])' + `{1,validNum}$`.replace('validNum', num));
      break;
    case validateType.email:
      regex = regEmail;
      if (isArray(text)) {
        for (let i = 0; i < text.length; i++) {
          if (!regex.test(text[i])) {
            return false;
          }
        }
        return true;
      }
      return regex.test(text);
    case validateType.socialNum:
      regex = regSocialNumber;
      break;
    case validateType.twMobile:
      regex = regTwMobile;
      break;
    case validateType.twPhone:
      regex = regTwPhone;
      break;
    case validateType.twNoAreaCodePhone:
      regex = regNoAreaCodeTwPhone;
      break;
    case validateType.twPostCode:
      regex = regTwPostCode;
      break;
    case validateType.twAreaCode:
      regex = regTwAreaCode;
      break;
    case validateType.twCityCall:
      regex = regTwCityCall;
      break;
    case validateType.twTaxId:
      regex = regTwTaxId;
      break;
    default:
      return false;
  }
  return !isEmpty(text) && regex.test(text);
};
