import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const memberSlice = createSlice({
  name: 'memberPage/member',
  initialState: {
    member: {},
    memberList: [],
    memberCount: 0,
  },
  reducers: {
    extraMember: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          member: action.payload,
        };
      }
    },
    updateMember: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          member: action.payload,
        };
      }
    },
    extraMembers: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          memberCount: action.payload.total,
          memberList: action.payload.results,
        };
      }
    },
    updateMembers: (state, action) => {
      const tempMemberList = state.memberList.some(member => member._id === action.payload._id)
        ? state.memberList.map(member => {
            if (member._id !== action.payload._id) return member;
            return action.payload;
          }) // edit member
        : [].concat(state.memberList, action.payload); //create member

      return {
        ...state,
        memberCount: state.memberList.some(member => member._id === action.payload._id)
          ? state.memberCount
          : state.memberCount + action.payload?.length,
        memberList: tempMemberList,
      };
    },
    createOrUpdateSingleMemberField: (state, action) => {
      const { memberId, field, value } = action.payload;
      const updatedMember = { ...state.memberList.find(member => member._id === memberId) };
      updatedMember[field] = value;

      return {
        ...state,
        memberList: state.memberList.map(member => {
          if (member._id === memberId) return updatedMember;
          return member;
        }),
      };
    },
  },
});

export const {
  extraMember,
  updateMember,
  extraMembers,
  updateMembers,
  deactivateMember,
  createOrUpdateSingleMemberField,
} = memberSlice.actions;

export default memberSlice.reducer;
