import { casLoginUrl, casLogoutUrl, casSwitchAuthUrl } from '@/constants/config';
import { appendStr, getHref, redirectHrefTo } from '@/utils/dentall-components';

export class SSORedirectUtil {
  static redirectCasLoginPath(useRecentUrl = true) {
    if (useRecentUrl) {
      return redirectHrefTo(appendStr(casLoginUrl, { redirect_url: getHref() }));
    }
    return redirectHrefTo();
  }

  static redirectCasSwitchAuthPath() {
    redirectHrefTo(appendStr(casSwitchAuthUrl, { redirect_url: getHref() }));
  }

  static redirectCasLogoutPath(enSctId, uniNum) {
    redirectHrefTo(
      appendStr(casLogoutUrl, {
        redirect_url: `${window.location.origin}`,
        enSctId,
        uniNum,
      }),
    );
  }
}
