import { isEmpty } from 'lodash';

const googleGeolocationUrl = 'https://www.googleapis.com/geolocation/v1/geolocate';

const parseAddressWithGeo = (code, locale) => {
  if (isEmpty(code)) return '';
  switch (locale) {
    case 'en':
      try {
        const splitStr = code.split(',');
        const address = splitStr[splitStr.length - 3].split(' ');
        const district = ` ${address[address.length - 2]} ${address[address.length - 1]}`;
        return `${splitStr[splitStr.length - 2].trim()}${district}`.trim();
      } catch (e) {
        return '';
      }
    case 'zh-TW':
    default:
      try {
        const splitStr = code.split(' ');
        const splitCountryStr = '台灣';
        if (splitStr[1].indexOf(splitCountryStr) !== -1) {
          return splitStr[1].split(splitCountryStr)[1].trim();
        }
        return splitStr[1].trim();
      } catch (e) {
        return '';
      }
  }
};

const getGeolocation = googleGeoKey => {
  return fetch(`${googleGeolocationUrl}?key=${googleGeoKey}`, { method: 'POST' })
    .then(res => res.json())
    .then(({ location = {} }) => {
      const { lat, lng } = location;
      return { lat, lng };
    })
    .catch(e => {
      console.log('fetch google geolocation failed: ', e);
      return {
        lat: null,
        lng: null,
      };
    });
};

export const getCurrentLocationByGeo = (googleGeoKey, locale) => {
  if (!googleGeoKey) return '';
  return getGeolocation(googleGeoKey).then(({ lat, lng }) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=${locale}&key=${googleGeoKey}`;
    return fetch(url)
      .then(res => res.json())
      .then(({ plus_code = {} }) => {
        const { compound_code = '' } = plus_code;
        if (!isEmpty(compound_code) && !isEmpty(locale)) {
          return `${parseAddressWithGeo(compound_code, locale)}`;
        }
        return '';
      })
      .catch(e => {
        console.log('fetch current location failed: ', e);
        return '';
      });
  });
};
