import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import store, { history } from './store';
import Loading from '@/components/Loading';
import Locale from '@/components/Locale';
import { AppRoutes } from '@/components/Routes';
import 'antd/dist/reset.css';
import './index.css';
import './globalStyles/index.less';
import { MessageGlobalStyle } from '@/hooks/useCustomMessage';

const container = document.getElementById('root');
const root = createRoot(container);

const styles = getComputedStyle(document.documentElement);

root.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <HelmetProvider>
        <StyleProvider hashPriority={'high'}>
          <ConfigProvider
            autoInsertSpaceInButton={false}
            theme={{
              hashed: false,
              token: {
                colorBorder: styles.getPropertyValue('--colorBorder'),
                colorPrimary: styles.getPropertyValue('--colorPrimary'),
                colorInfo: styles.getPropertyValue('--colorInfo'),
                colorLink: styles.getPropertyValue('--colorLink'),
                colorTextPlaceholder: styles.getPropertyValue('--colorTextPlaceholder'),
                controlItemBgActive: styles.getPropertyValue('--controlItemBgActive'),
              },
            }}
          >
            <Locale>
              <Suspense fallback={<Loading />}>
                <MessageGlobalStyle />
                <AppRoutes />
              </Suspense>
            </Locale>
          </ConfigProvider>
        </StyleProvider>
      </HelmetProvider>
    </BrowserRouter>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
