import _ from 'lodash';
import { splitStr } from '@/utils/dentall-components';

export const parseAccountDetail = user => {
  if (!user) return {};

  let accountId, accountName, accountEmail, accountPhone;
  const { is_clinic_account = false, facebook, email, email_1, mobile, clinic = {} } = user;
  if (is_clinic_account) {
    accountName = !_.isEmpty(clinic?.name) ? clinic?.name : user?.name || '';
    accountId = splitStr(email, '@')[0];
  } else {
    accountName = user?.name || '';
    accountPhone = mobile;
    accountEmail =
      !_.isEmpty(facebook) && !_.isEmpty(facebook?.id) ? (!_.isEmpty(email_1) ? email_1 : undefined) : email;
  }

  return {
    accountId,
    accountName,
    accountEmail,
    accountPhone,
  };
};
