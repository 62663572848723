import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const wsSlice = createSlice({
  name: 'wsPage/workspace',
  initialState: {
    ws: {},
  },
  reducers: {
    extraWs: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          ws: action.payload,
        };
      }
    },
    updateWs: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          ws: action.payload,
        };
      }
    },
  },
});

export const { extraWs, updateWs } = wsSlice.actions;

export default wsSlice.reducer;
