import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import settingReducer from './settingSlice';

const homePageReducer = combineReducers({
  authentication: authReducer,
  setting: settingReducer,
});

export default homePageReducer;
