import { lazy } from 'react';
import { parseAccountDetail } from '@/utils/account';
const MainLayout = lazy(() => import('@/components/Layout/MainLayout'));
const GeneralLayout = lazy(() => import('@/components/Layout/GeneralLayout'));
const WorkspacePage = lazy(() => import('@/container/WorkspacePage'));
const MemberPage = lazy(() => import('@/container/MemberPage'));
const HistoryPage = lazy(() => import('@/container/HistoryPage'));
const SettingPage = lazy(() => import('@/container/SettingPage'));
const WelcomePage = lazy(() => import('@/container/WelcomePage'));
const WsListPage = lazy(() => import('@/container/WorkspacePage/WsListPage'));
const CreateWsPage = lazy(() => import('@/container/CreateWsPage'));
const InvitationPage = lazy(() => import('@/container/InvitationPage'));

const Error = lazy(() => import('@/components/Error'));

export const mainRoutes = (currentUser, isNewcomer) => {
  const accountInfo = parseAccountDetail(currentUser);

  return [
    {
      path: '/:wsId',
      name: 'main',
      key: 'main',
      element: (
        <MainLayout
          currentUser={currentUser}
          isClinicAccount={!!currentUser?.is_clinic_account}
          accountInfo={accountInfo}
          isNewcomer={isNewcomer}
        />
      ),
      exact: true,
      children: [
        { index: true, element: <WorkspacePage /> },
        { path: 'member', element: <MemberPage /> },
        { path: 'history', element: <HistoryPage /> },
        { path: 'setting', element: <SettingPage /> },
      ],
    },
    {
      path: '/',
      name: 'welcome',
      key: 'welcome',
      element: (
        <GeneralLayout
          currentUser={currentUser}
          isClinicAccount={!!currentUser?.is_clinic_account}
          accountInfo={accountInfo}
          isNewcomer={isNewcomer}
        />
      ),
      children: [
        { index: true, element: <WelcomePage /> },
        { path: 'list', element: <WsListPage isNewcomer={isNewcomer} /> },
        { path: 'bind', element: <InvitationPage /> },
        { path: 'create', element: <CreateWsPage /> },
      ],
    },
    {
      path: '*',
      element: (
        <Error currentUser={currentUser} isClinicAccount={!!currentUser?.is_clinic_account} accountInfo={accountInfo} />
      ),
    },
  ];
};
