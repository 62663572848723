import { combineReducers } from '@reduxjs/toolkit';
import memberSlice from './memberSlice';
import characterSlice from './characterSlice';

const memberPageReducer = combineReducers({
  member: memberSlice,
  character: characterSlice,
});

export default memberPageReducer;
