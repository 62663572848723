import { createSlice } from '@reduxjs/toolkit';

const settingSlice = createSlice({
  name: 'homePage/settings',
  initialState: {
    // locale: navigator.language,
    locale: 'zh-TW',
    openKeysInMenu: ['clinic', 'prescriptionItems'],
  },
  reducers: {
    changeLocale: (state, action) => {
      state.locale = action.payload;
    },
    updateOpenKeysInMenu: (state, action) => {
      state.openKeysInMenu = action.payload;
    },
  },
});

export const { changeLocale, updateOpenKeysInMenu } = settingSlice.actions;
export default settingSlice.reducer;
