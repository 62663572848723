import _ from 'lodash';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { getGeoLocations, setGeoLocations } from '@/utils/token';
import { getCurrentLocationByGeo } from '@/utils/dentall-components/geo';
import { googleGeoKey } from '@/constants/config';
import zh from '../../lang/zhTW.json';
import en from '../../lang/en.json';

const localeReSelector = createSelector(
  state => state.homePageReducer.setting,
  ({ locale }) => ({
    locale,
  }),
);

const messageList = {
  'zh-TW': zh,
  en,
};

// export const locales = [
//   { value: 'zh-TW', label: '中文' },
//   { value: 'en', label: 'English' },
// ];
const Locale = ({ children }) => {
  const { locale } = useSelector(localeReSelector);
  const geoLocations = getGeoLocations();

  useEffect(() => {
    const setGeo = async () => {
      setGeoLocations(await getCurrentLocationByGeo(googleGeoKey, locale), locale);
    };

    if (_.isEmpty(geoLocations?.locations) || !_.isEqual(locale, geoLocations?.locale)) {
      setGeo();
    }
  }, [geoLocations, locale]);

  return (
    <IntlProvider locale={locale} messages={messageList[locale]} defaultLocale={locale} key={locale}>
      {children}
    </IntlProvider>
  );
};

export default Locale;
