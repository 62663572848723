import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createSelector } from '@reduxjs/toolkit';
import {
  getSuccessTokenId,
  removeAuthTokens,
  saveEnSctId,
  saveSuccessTokenData,
  saveSuccessTokenId,
} from '@/utils/token';
import { useStVerifyQuery, useSctIdVerifyQuery } from '@/services/cas/auth';
import { useGetComerStatusQuery } from '@/services/dashboard';
import { appInitFetching, updateCurrentUser } from '@/container/HomePage/slice/authSlice';
import { appendStr } from '@/utils/dentall-components';

const name = 'appInit';

const authReSelector = createSelector(
  state => state.homePageReducer.authentication,
  ({ currentUser, isOk, fetchStatus, isNewcomer }) => ({
    currentUser,
    isOk,
    isFetching: fetchStatus.appInit,
    isNewcomer,
  }),
);

export function useAuth() {
  const { currentUser } = useSelector(authReSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search = '' } = useLocation();
  const [searchParams] = useSearchParams();
  const ST = searchParams.get('ST') || '';
  const sctId = getSuccessTokenId();
  const isStEmpty = _.isEmpty(ST);
  const isSctIdEmpty = _.isEmpty(sctId);
  const stVerifyRes = useStVerifyQuery({ ST }, { skip: isStEmpty });
  const sctIdVerifyRes = useSctIdVerifyQuery({ sctId }, { skip: !(isStEmpty && !isSctIdEmpty) });
  useGetComerStatusQuery(null, { skip: isSctIdEmpty || _.isEmpty(currentUser) });

  useEffect(() => {
    dispatch(appInitFetching({ [name]: true }));
    if (!stVerifyRes?.data) {
      dispatch(appInitFetching({ [name]: false }));
    } else {
      const { SCT_ID, exp = 30, err } = stVerifyRes.data;
      if (err) {
        console.error('Failed to verify ST: ', err);
        removeAuthTokens(SCT_ID);
        dispatch(updateCurrentUser());
      }
      if (SCT_ID) {
        saveSuccessTokenId(SCT_ID, exp);
        const params = new URLSearchParams(search);
        let queriedStr = Object.fromEntries([...params]);
        delete queriedStr.ST;
        delete queriedStr.enSctId;
        queriedStr = _.isEmpty(queriedStr) ? '' : appendStr('', queriedStr);
        navigate(`${window.location.pathname}${queriedStr}`);
      }
      dispatch(appInitFetching({ [name]: false }));
    }
  }, [dispatch, navigate, stVerifyRes, search]);

  useEffect(() => {
    dispatch(appInitFetching({ [name]: true }));
    if (!sctIdVerifyRes?.data) {
      dispatch(appInitFetching({ [name]: false }));
    } else {
      const { enSctId, uniNum, user, err } = sctIdVerifyRes.data;
      const tokenId = getSuccessTokenId();
      if (err) {
        console.error('Failed to verify SCT: ', err);
        removeAuthTokens(tokenId);
        dispatch(updateCurrentUser());
      }
      if (!_.isEmpty(user)) {
        saveSuccessTokenData(tokenId, uniNum, enSctId);
        saveEnSctId(enSctId);
        dispatch(updateCurrentUser(user));
      }
      dispatch(appInitFetching({ [name]: false }));
    }
  }, [dispatch, sctIdVerifyRes]);

  return useSelector(authReSelector);
}
