import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const notifySlice = createSlice({
  name: 'wsPage/notify',
  initialState: {
    notify: [],
  },
  reducers: {
    extraNotify: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          notify: action.payload,
        };
      }
    },
    updateNotify: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          notify: state.notify.filter(({ _id }) => _id !== action.payload),
        };
      }
    },
  },
});

export const { extraNotify, updateNotify } = notifySlice.actions;

export default notifySlice.reducer;
