import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const wsListSlice = createSlice({
  name: 'wsPage/wsList',
  initialState: {
    wsList: [],
  },
  reducers: {
    extraWsList: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        return {
          ...state,
          wsList: action.payload,
        };
      }
    },
  },
});

export const { extraWsList } = wsListSlice.actions;

export default wsListSlice.reducer;
