import React, { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';
import cx from 'classnames';
import { ReactComponent as CheckCircleIcon } from '@/images/icon-checkmark-circle-fill.svg';
import { ReactComponent as ErrorIcon } from '@/images/icon-alert-circle-fill.svg';

// region - style
export const messageContainerName = 'sheeta-message-container';
export const MessageGlobalStyle = createGlobalStyle`
  .ant-message-notice-content {
    background-color: var(--sheeta--basic-100);
    color: var(--sheeta--basic-100);
    max-width: 100%;
    width: auto;
    min-height: 56px;
    height: auto;
    text-align: start;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 12px !important;

    // 如果你有自定義 icon (class=custom-icon), 且有給 type 的話，就隱藏原本提供的
    .ant-message-custom-content.ant-message-info,
    .ant-message-custom-content.ant-message-success,
    .ant-message-custom-content.ant-message-warning,
    .ant-message-custom-content.ant-message-error {
      :has(.custom-icon) {
        & > span:first-of-type {
          display: none !important;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .ant-message-notice-content {
      width: auto;
      max-width: 704px;
      min-width: 308px;
    }
  }

  @media screen and (max-width: 767px) {
    .ant-message-notice-content {
      min-width: 308px;
      max-width: 100%;
      width: fit-content;
    }
  }

  .ant-message-custom-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-message-custom-content > svg {
    width: 25px;
    height: 25px;
    align-self: flex-start;
  }

  .ant-message-custom-content > span {
    flex: 1;
  }

  .ant-message-custom-content span {
    word-break: break-all;
  }

  .ant-message-notice-success.sheeta-message-container {
    .ant-message-notice-content {
      background-color: var(--success-600) !important;
      box-shadow: 0 5px 15px 0 rgba(0, 179, 131, 0.5) !important;
    }
  }

  .ant-message-notice-error.sheeta-message-container {
    .ant-message-notice-content {
      background-color: var(--sheeta--danger-500);
      box-shadow: 0 5px 15px 0 rgba(246, 78, 95, 0.5);
    }
  }

  .ant-message-notice-warning.sheeta-message-container {
    .ant-message-notice-content {
      background-color: var(--warning-500);
      box-shadow: 0 5px 15px 0 rgba(254, 159, 67, 0.5);
    }
  }
`;
export const MessageMainContent = styled.div.attrs({
  className: 'msg-content',
})`
  display: flex;
  align-items: center;
  > :first-child {
    display: flex;
    align-items: center;
    margin-right: 8px;
    width: 25px;
    height: 25px;
  }
  font-size: 18px;
  font-weight: bold;
  line-height: 56px;
  word-break: break-all;
`;
// endregion

export const CustomMessageIcon = styled.div.attrs(() => ({
  className: 'custom-icon',
}))``;
export const ErrorMessageIcon = () => <CustomMessageIcon as={ErrorIcon} />;
export const SuccessMessageIcon = () => <CustomMessageIcon as={CheckCircleIcon} />;

export const MessageStatus = {
  success: 'success',
  error: 'error',
};

const useCustomMessage = (getContainer, duration = 2) => {
  const [messageApi, contextHolder] = message.useMessage({ getContainer });
  const [api, setApi] = useState(messageApi);

  useEffect(() => {
    setApi(messageApi);
  }, [messageApi]);

  const showMessage = useCallback(
    ({ type, content, key = '', className = '' }) => {
      if (api) {
        if (type) {
          api[type]({ content, key, duration, className: cx(messageContainerName, className) });
        } else {
          api.open({ content, key, duration, className: cx(messageContainerName, className) });
        }

        setTimeout(() => {
          api.destroy(key);
        }, duration * 1000);
      }
    },
    [api, duration],
  );

  return [showMessage, contextHolder];
};

export default useCustomMessage;
