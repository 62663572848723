import { lazy } from 'react';
import { parseAccountDetail } from '@/utils/account';

const GeneralLayout = lazy(() => import('@/components/Layout/GeneralLayout'));
const CreateWsPage = lazy(() => import('@/container/CreateWsPage'));
const InvitationPage = lazy(() => import('@/container/InvitationPage'));
const WsListPage = lazy(() => import('@/container/WorkspacePage/WsListPage'));
const Error = lazy(() => import('@/components/Error'));

export const generalRoutes = (currentUser, isNewcomer) => {
  const accountInfo = parseAccountDetail(currentUser);

  return [
    {
      path: '/',
      name: 'general',
      key: 'general',
      element: (
        <GeneralLayout
          currentUser={currentUser}
          isClinicAccount={!!currentUser?.is_clinic_account}
          accountInfo={accountInfo}
          isNewcomer={isNewcomer}
        />
      ),
      children: [
        { index: true, element: <CreateWsPage /> },
        { path: 'bind', element: <InvitationPage isNewcomer={isNewcomer} /> },
        { path: 'list', element: <WsListPage isNewcomer={isNewcomer} /> },
        {
          path: ':wsId',
          element: (
            <Error
              currentUser={currentUser}
              isClinicAccount={!!currentUser?.is_clinic_account}
              accountInfo={accountInfo}
              isNewcomer={isNewcomer}
            />
          ),
        },
      ],
    },
    {
      path: '/:wsId',
      name: 'main',
      key: 'main',
      element: (
        <GeneralLayout
          currentUser={currentUser}
          isClinicAccount={!!currentUser?.is_clinic_account}
          accountInfo={accountInfo}
          isNewcomer={isNewcomer}
        />
      ),
      exact: true,
      children: [
        {
          path: '*',
          element: (
            <Error
              currentUser={currentUser}
              isClinicAccount={!!currentUser?.is_clinic_account}
              accountInfo={accountInfo}
              isNewcomer={isNewcomer}
            />
          ),
        },
      ],
    },
    {
      path: '*',
      element: (
        <Error
          currentUser={currentUser}
          isClinicAccount={!!currentUser?.is_clinic_account}
          accountInfo={accountInfo}
          isNewcomer={isNewcomer}
        />
      ),
    },
  ];
};
