import { baseApi } from './api';
import { apiHeadersType } from '@/constants/config';
import { updateComerStatus } from '@/container/HomePage/slice/authSlice';
import { extraWsList } from '@/container/WorkspacePage/slice/wsListSlice';

const prefix = `/dashboard`;

export const dashboardApi = baseApi.injectEndpoints({
  endpoints: build => ({
    getComerStatus: build.query({
      query: () => {
        return {
          url: `${prefix}/is-newcomer`,
          method: 'GET',
          headers: {
            'content-type': apiHeadersType.jsonType,
          },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateComerStatus(data));
        } catch (err) {
          console.error(err);
        }
      },
    }),
    createWorkspace: build.mutation({
      query: arg => {
        return {
          url: `${prefix}/create-workspace`,
          method: 'POST',
          body: arg,
        };
      },
    }),
    getWsList: build.query({
      query: () => {
        return {
          url: `${prefix}/workspace-list`,
          method: 'GET',
          headers: {
            'content-type': apiHeadersType.jsonType,
          },
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(extraWsList(data));
        } catch (err) {
          console.error(err);
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetComerStatusQuery, useCreateWorkspaceMutation, useGetWsListQuery } = dashboardApi;
