import { createSlice } from '@reduxjs/toolkit';

const historySlice = createSlice({
  name: 'historyPage/history',
  initialState: {
    total: 0,
    histories: [],
  },
  reducers: {
    extraHistories: (state, action) => {
      return {
        ...state,
        total: action.payload.total,
        histories: action.payload.results,
      };
    },
  },
});

export const { extraHistories } = historySlice.actions;

export default historySlice.reducer;
