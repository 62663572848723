import styled from 'styled-components';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--conditional-page-background);

  & > div {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      &:nth-child(1) {
        margin-top: 4px;
      }

      margin: 0;
      color: var(--sheeta--basic-1000);
      font-size: 14px;
      line-height: 1.43;
      font-weight: initial;
      text-align: center;
    }
  }

  &.in-content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0;
    left: 0;
    top: 0;
    background: var(--conditional-page-background);
  }
`;

const Loading = ({ inContent = false, title, msg }) => {
  return (
    <Container className={inContent && 'in-content'}>
      <div>
        <Spin />
        <div>
          {title && <p>{title}</p>}
          {msg && <p>{msg}</p>}
        </div>
      </div>
    </Container>
  );
};

export default Loading;

Loading.propTypes = {
  inContent: PropTypes.bool,
  title: PropTypes.string,
  msg: PropTypes.string,
};

Loading.defaultProps = {
  inContent: false,
  title: undefined,
  msg: undefined,
};
