import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getGeoLocations, getSuccessTokenId } from '@/utils/token';

const baseUrl = process.env.REACT_APP_SELF_API_URL;

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      headers.set('sctId', `${getSuccessTokenId()}`);
      headers.set('location', `${encodeURIComponent(getGeoLocations()?.locations)}`);
      return headers;
    },
  }),
  reducerPath: 'baseApi',
  endpoints: () => ({}),
});
